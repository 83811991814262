<template>
  <div class="fragment">
    <ErrorLogsTableAdmin
        v-if="isAdmin"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
        @deleteChecked="deleteChecked"
    />


  </div>

</template>

<script>
  import ErrorLogsTableAdmin from "./ErrorLogsAdmin/ErrorLogsAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ErrorLogsTable",
    components: {
      ErrorLogsTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getErrorLogsBackendForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterDate: [],
        filterStatus: '',
        filterLevel: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          date: this.$route.query.date,
          status: this.$route.query.status,
          level: this.$route.query.level,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextErrorLogsBackendPage', true)
        this.$store.dispatch('fetchErrorLogsBackend', url).then(() => {
          this.$store.commit('setNextErrorLogsBackendPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })

        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterLevel: 'BackendLogLevel',
            // filterDate: 'ForbiddenItemKeywordId',
          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('between_created_at', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      deleteChecked() {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          let removeIds = this.getCheckedRows('row-name')

          this.$store.dispatch('deleteCheckedErrorLogsBackend', {logIds: removeIds}).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.reload()
                this.openNotify('success', 'common_notificationRecordDeleted')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      }

    }

  }
</script>

<style scoped>

</style>
