<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Id'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('forbiddenKeywords_Id.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="id"-->
<!--        />-->
<!--      </div>-->
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--        <span class="admin-edit" @click="editTranslate(['fastOrders_created'])"></span>-->
        <DatePickerDefault
            :label="$t('fastOrders_created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <DefaultInput
            :label="'Backend Log Level'"
            :type="'text'"
            v-model="level"
        />
      </div>
<!--      <div class="table-filter__item w-100 mw-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Status'])"></div>-->
<!--        <DefaultCheckbox-->
<!--                :value="status === 'true' || this.status === true"-->
<!--                :label="$t('forbiddenKeywords_IsActiveStatus.localization_value.value')"-->
<!--                @input="(val) => {this.status = val ? val : ''}"-->
<!--        />-->
<!--      </div>-->

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from "vue2-datepicker";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ErrorLogsFilter",
    components: {
      DatePickerDefault,
      DatePicker,
      // DefaultCheckbox,
      FilterBlock,
      MainButton,
      DefaultInput,
      // DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc],

    data(){
      return {

        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        level: this.filterGetParams.level ? this.filterGetParams.level : '',

        filterCounts: [
          'id',
          'keyword',
          'status',
          'date',
          'level',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.status = newVal.status ? newVal.status : ''
        this.level = newVal.level ? newVal.level : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
