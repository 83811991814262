<template>
  <div>
    <ErrorLogsHead
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
        @selectAll="selectAll"
        @deleteChecked="$emit('deleteChecked')"
    />

    <div class="table-filter-wrap">
      <ErrorLogsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ErrorLogsTable
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


import ErrorLogsHead from "./ErrorLogsHead/ErrorLogsHead";
import ErrorLogsFilter from "./ErrorLogsFilter/ErrorLogsFilter";
import ErrorLogsTable from "./ErrorLogsTable/ErrorLogsTable";

export default {
  name: "ErrorLogsTableAdmin",

  components: {
    ErrorLogsHead,
    ErrorLogsFilter,
    ErrorLogsTable,
  },

  props: {
    countFilterParams: Number,
    filterGetParams: Object,
  },

  data() {
    return {
      showFilter: false,
      selectedNow: false,
    }
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    selectAll(val) {
      this.selectedNow = val
    },
  }
}
</script>

<style scoped>

</style>
