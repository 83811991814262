<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i">
          <DefaultCheckbox
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>
        <div v-if="isAdmin" class="btn-left-block-i">
          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i">
              <TopTableBoxesBtn
                  class="btn-left-block-i--link"
                  :type="'dog'"
                  :title="'errorLogs_deleteChecked'"
                  @click.native="$emit('deleteChecked')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn-right-block">

      </div>
    </div>




  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TopTableBoxesBtn from "@/components/coreComponents/Buttons/TopTableBoxesBtn/TopTableBoxesBtn";
  export default {
    name: "ErrorLogsHead",
    components: {
      TopTableBoxesBtn,
      DefaultCheckbox,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{

        isModalErrorLogsPopup: false,
      }
    },

    methods: {

      changeErrorLogsPopup(val, id = false) {
        if(id){
          this.orderId = id
        }
        this.isModalErrorLogsPopup = val

        if(!val){
          this.orderId = -1
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

